@media screen and (min-width: 1024px) {
  input::placeholder {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }

  .WidgetList_ScrollContainer::-webkit-scrollbar {
    width: 6px;
  }

  .WidgetList_ScrollContainer::-webkit-scrollbar-track {
    width: 6px;
    background-color: #f0f0f0;
    border-radius: 10px;
  }

  .WidgetList_ScrollContainer::-webkit-scrollbar-thumb {
    background-color: rgb(204, 204, 204);
    border-radius: 10px;
  }

  .WorkScheduleTable_Required_Input_BG::placeholder {
    color: rgb(216, 44, 44); /* 원하는 색상으로 변경 */
    font-size: 12px;
    text-decoration: underline;
    background-color: rgb(255, 239, 190);
  }

  .WorkScheduleTable_Input_BG::placeholder {
    font-size: 12px;
  }

  .SalaryCompositionTable_Input_BG_Title_Required::placeholder {
    color: rgb(216, 44, 44); /* 원하는 색상으로 변경 */
    font-size: 12px;
    text-decoration: underline;
    background-color: rgb(255, 239, 190);
  }

  .SalaryCompositionTable_Input_BG_Amount_Required::placeholder {
    color: rgb(216, 44, 44); /* 원하는 색상으로 변경 */
    font-size: 12px;
    text-decoration: underline;
    background-color: rgb(255, 239, 190);
  }

  .SalaryCompositionTable_Input_BG_Standard_Required::placeholder {
    color: rgb(216, 44, 44); /* 원하는 색상으로 변경 */
    font-size: 12px;
    text-decoration: underline;
    background-color: rgb(255, 239, 190);
  }

  .SalaryCompositionTable_Input_BG_Title::placeholder {
    font-size: 12px;
  }

  .SalaryCompositionTable_Input_BG_Amount::placeholder {
    font-size: 12px;
  }

  .SalaryCompositionTable_Input_BG_Standard::placeholder {
    font-size: 12px;
  }

  .SalaryCompositionTable_Input_BG_Note::placeholder {
    font-size: 12px;
  }

  .PartnershipAgreement_TableInput::placeholder {
    color: rgb(216, 44, 44); /* 원하는 색상으로 변경 */
    background-color: rgb(255, 239, 190);
    text-decoration: underline;
    font-size: 14px;
  }

  .Leave_LongInput_BG::placeholder {
    font-size: 16px;
  }

  textarea::placeholder {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }

  .Flex_App {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
  }

  .Screen_CT {
    display: flex;
    flex-direction: row;
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
    background-color: #fcfcfc;
  }

  .FlexContent_Login {
    display: flex;

    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100vh;
  }

  .FlexContent_Other {
    display: flex;

    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
  }

  .FlexContent_Other_V {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
    /* background-color: aqua; */
  }

  .AllContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .AllContent_Default_C {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 75vw;
    height: 100%;
    /* margin-top: 64px; */
    /* background-color: blueviolet; */
    margin-left: 20vw;
  }

  .AllContent_Default_V {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 75vw;
    margin-top: 112px;
    margin-left: 20vw;
  }

  .AllContent_Default_V2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding-bottom: 72px;
  }

  .AllContent_Between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100vh;
    padding-left: 4vw;
    padding-right: 4vw;
  }

  .Required_Dot_Red {
    width: 6px;
    height: 6px;
    background-color: rgb(216, 44, 44);
    border-radius: 3px;
    margin-left: 8px;
  }

  .Required_Dot_Blue {
    width: 6px;
    height: 6px;
    background-color: rgb(55, 105, 252);
    border-radius: 3px;
    margin-left: 8px;
  }

  .Required_Dot_Red_TBD {
    width: 6px;
    height: 6px;
    background-color: #ff7373;
    border-radius: 3px;
    margin-left: 4px;
  }

  .Required_Dot_Blue_TBD {
    width: 6px;
    height: 6px;
    background-color: rgb(55, 105, 252);
    border-radius: 3px;
    margin-left: 4px;
  }

  .GNB_BG {
    display: flex;
    flex-direction: row;
    position: fixed;
    z-index: 3;
    box-shadow: none;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 48px;
    /* height: 96px; */
    background-color: rgb(255, 255, 255);
    justify-content: space-between;
    align-items: center;
  }

  .GNB_BG_V {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 3;
    box-shadow: none;
    top: 0px;
    left: 0px;
    width: 237px;
    height: 100%;
    /* height: 96px; */
    background-color: rgb(255, 255, 255);
    justify-content: space-between;
    align-items: flex-start;

    border-style: solid;
    /* border-color: rgb(0, 128, 255); */
    border-color: rgb(242, 244, 246);
    border-right-width: 3px;
    border-left-width: 0px;
    border-top-width: 0px;
    border-bottom-width: 0px;
  }

  .GNB_LeftContents {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: auto;
    margin-left: 4vw;
  }

  .GNB_LogoCT_V {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
    margin-top: 24px;
  }

  .GNB_Upper_V {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .GNB_Lower_V {
    display: flex;
    flex-direction: column;
  }

  .GNB_MenuBar_CT {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }

  .GNB_MenuBar_BG_On {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 4px;
    width: 220px;
    height: 40px;
    padding: 0px;
    border-style: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: rgb(231, 239, 255);
  }

  .GNB_MenuBar_BG_Off {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 4px;
    width: 220px;
    height: 40px;
    padding: 0px;
    border-style: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
  }

  .GNB_MenuBar_Contents {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }

  .GNB_MenuBar_Label_On {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    margin-left: 12px;
  }

  .GNB_MenuBar_Label_Off {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    margin-left: 12px;
  }

  .GNB_MenuBar_Divider {
    margin-top: 8px;
    margin-bottom: 12px;
    margin-left: 10px;
    width: 220px;
    height: 2px;
    background-color: rgb(227, 227, 227);
  }

  .GNB_Label_Underline {
    display: flex;
    z-index: 1;
    text-decoration: none;
  }

  .GNB_Logo {
    display: flex;
    z-index: 1;
    width: 78px;
    /* width: 156px; */
    height: 18px;
    /* height: 36px; */
  }

  .GNB_Logo_V {
    display: flex;
    z-index: 1;
    width: 167px;
    /* width: 156px; */
    height: 24px;
    /* height: 36px; */
  }

  .GNB_CompanyProfile {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;
  }

  .GNB_CompanyLogo {
    display: flex;
    z-index: 1;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    overflow: hidden;
  }

  .GNB_CompanyName_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -3%;

    margin-left: 8px;
  }

  .GNB_SettingIcon_CT {
    display: flex;
    z-index: 1;
    margin-left: 8px;
    width: 24px;
    height: 24px;
  }

  .GNB_RightContents {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: auto;
    /* margin-right: 4vw; */
  }

  .GNB_Logout_CT {
    display: flex;
  }

  .GNB_Profile_CT {
    display: flex;
    margin-left: 12vw;
  }

  .GNB_Logout_Button {
    padding: 0px;
    margin: 0px;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    border: none;
  }

  .Home_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Login_Head_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 48px;
    letter-spacing: -3%;

    margin-top: 160px;
    margin-bottom: 48px;
  }

  .Login_Auto_Copy {
    color: rgb(121, 121, 121);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: -3%;

    margin-left: 2px;
  }

  .Login_TextButton_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: -3%;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 0px;
    padding: 0px;
  }

  .Login_VerticalLine {
    display: flex;
    width: 1px;
    height: 16px;
    background-color: rgb(170, 170, 170);
    margin-left: 4px;
    margin-right: 4px;
  }

  .Login_Input_BG {
    display: flex;
    flex-direction: row;
    width: 160px;
    height: 42px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(170, 170, 170);
    background-color: rgb(255, 255, 255);

    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    padding-left: 8px;
    padding-top: 0px;
    padding-right: 8px;
    padding-bottom: 0px;
    outline: none;
  }

  .LoginButton_BG {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    width: 228px;
    height: 64px;
    background-color: rgb(55, 105, 252);
    cursor: pointer;
  }

  .Login_Button_One_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 24px;
  }

  .LoginButton_One_BG {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    width: 180px;
    height: 44px;
    background-color: rgb(55, 105, 252);
    cursor: pointer;
  }

  .LoginButton_Copy_CT {
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .LoginButton_Copy {
    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
  }

  .Login_TextButton_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    width: 320px;
  }

  .Login_RightContents_CT {
    display: flex;
    flex-direction: row;
  }

  .Login_SigninButton_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .Login_SigninButton {
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-top: 48px;
  }

  .Login_Signin_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -3%;
  }

  .Login_SigninButton_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -3%;

    margin-left: 4px;
  }

  .Login_Auto_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .Signin_Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: flex-start;
    width: 480px;
    margin-top: 48px; */
  }

  .Signin_Content_V {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: space-between;
    width: 100%;
    /* margin-top: 48px; */
  }

  .Signin_InputField_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .Signin_ShortInput_BG {
    display: flex;
    flex-direction: row;
    width: 434px;
    height: 40px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);

    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .Signin_TextArea_BG {
    display: flex;
    flex-direction: row;
    width: 434px;
    height: 96px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);

    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 8px;
    padding-right: 8px;
    resize: none;
    outline: none;
  }

  .Signin_LongInput_BG_None {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 450px;
    height: 40px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    outline: none;
  }

  .Signin_LongInput_BG {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 462px;
    height: 40px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding-left: 8px;
    padding-right: 8px;

    outline: none;
  }

  .Signin_Line {
    display: flex;
    flex-direction: row;
    width: 452px;
    height: 2px;
    background-color: rgb(227, 227, 227);
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .Signin_CompleteModal_BG {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  .Issue_Seal_Modal_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }

  .Issue_Error_Text_CT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .Issue_Error_Complete_Text_CT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 24px;
  }

  .Signin_CompleteModal_CT {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    border-radius: 12px;
    width: 360px;
  }

  .Signin_CompleteModal_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: -3%;
    margin-top: 16px;
  }

  .Signin_CompleteModal_Button_CT {
    display: flex;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 24px;
    cursor: pointer;
  }

  .Signin_CompleteModal_Button {
    margin: 0px;
    padding: 0px;
    border: none;
    text-decoration: none;
    background-color: rgb(55, 105, 252);
    border-radius: 12px;
    width: 128px;
    height: 48px;
    cursor: pointer;
  }

  .Signin_CompleteModal_Button_Copy {
    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: -3%;
  }

  .Email_Button {
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-top: 48px;
  }

  .Issue_Error_Button {
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-top: 24px;
  }

  .Email_Button_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .Email_Button_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    letter-spacing: -3%;
  }

  .Email_CT {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }

  .Email_Block_All_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
  }

  .Email_Block_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .Email_Block_Icon_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 240px;
    margin-bottom: 24px;
  }

  .Email_Issue_Content {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  .Email_LeftArea_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 96px;
    min-width: 720px;
    height: 788px;
  }

  .Issue_Complete_LeftArea_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 96px;
    height: 480px;
  }

  .Email_LeftContents_CT {
    display: flex;
    flex-direction: column;
  }

  .Email_LeftContents_CT_Reject {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .Email_Main_1_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    letter-spacing: -3%;
  }

  .Email_Main_2_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    letter-spacing: -3%;
  }

  .Email_Main_Copy_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .Email_Main_1_Copy_CT {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 4px;
  }

  .Email_Sub_Copy_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 16px;
  }

  .Email_Sub_1_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    letter-spacing: -3%;
    line-height: 130%;
  }

  .Email_Sub_2_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    letter-spacing: -3%;
    line-height: 150%;
  }

  .Email_Sub_Error_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: -3%;
    line-height: 130%;
    margin-bottom: 4px;
  }

  .Email_Sub_ETC_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: -3%;
    line-height: 130%;
    margin-top: 16px;
  }

  .Email_InfoBox_BG {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 30vw;
    height: auto;
    border-style: solid;
    border-radius: 12px;
    border-width: 1px;
    border-color: rgb(246, 250, 255);
    background-color: rgb(246, 250, 255);
    padding: 24px;
  }

  .Email_InfoBox_BG_CT {
    display: flex;
    flex-direction: column;
    margin-top: 36px;
  }

  .Email_InfoBox_1_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -3%;
  }

  .Email_InfoBox_1_Copy_CT {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }

  .Email_InfoBox_2_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -3%;
    line-height: 130%;
  }

  .Email_InfoIcon_CT {
    width: 60%;
    max-width: 582px;
    max-height: 586px;
    margin-top: 64px;
  }

  .Email_LawInfo_Copy_CT {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    width: 30vw;
    align-items: center;
    justify-content: center;
    align-content: center;
    padding-left: 24px;
    padding-right: 24px;
  }

  .Email_LawInfo_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -3%;
    line-height: 130%;
  }

  .Email_LawProfile_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .Email_LawProfileImage_CT {
    width: 64px;
    height: 64px;
  }

  .Email_LawProfile_Left_CT {
    display: flex;
    flex-direction: row;
    margin-right: 24px;
  }

  .Email_LawProfile_Right_CT {
    display: flex;
    flex-direction: column;
  }

  .Email_LawProfile_Intro_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .Email_LawProfile_Company_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: -3%;
    line-height: 130%;
  }

  .Email_LawProfile_Name_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -3%;
    line-height: 130%;
  }

  .Email_LawProfile_Vertical_CT {
    width: 1px;
    height: 16px;
    background-color: rgb(170, 170, 170);
    margin-left: 8px;
    margin-right: 8px;
  }

  .Email_LawProfile_Detail_CT {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
  }

  .Email_LawProfile_Tel_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: -3%;
    line-height: 130%;
  }

  .Email_RightArea_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 96px;
    height: auto;
    margin-right: 72px;
  }

  .Email_RightArea_Icon_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 96px;
    height: auto;
  }

  .Issue_Conplete_RightArea_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 96px;
    height: auto;
    margin-right: 80px;
  }

  .Email_PDF_BG {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 420px;
    height: 594px;
    background-color: rgb(255, 255, 255);
    padding: 24px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }

  .Email_PDF_Upper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Email_PDF_Lower {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Email_PDF_CompanyHead_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  .Email_CompanyHead_Input_BG {
    display: flex;
    flex-direction: row;
    width: 116px;
    height: 32px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(55, 105, 252);
    background-color: rgb(255, 255, 255);

    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    padding-left: 8px;
    padding-right: 8px;

    outline: none;
  }

  .Email_PDF_IssueNumber_Copy {
    color: rgb(70, 70, 70);
    font-family: "NanumMyeongjo";
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    letter-spacing: -3%;
    width: 420px;
  }

  .Email_PDF_Title_Copy {
    color: rgb(70, 70, 70);
    font-family: "NanumMyeongjoBold";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    word-spacing: 16px;
    margin-top: 48px;
  }

  .Email_PDF_Body_Copy {
    color: rgb(70, 70, 70);
    font-family: "NanumMyeongjoBold";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    margin-top: 32px;
  }

  .Email_PDF_Date_Copy {
    color: rgb(70, 70, 70);
    font-family: "NanumMyeongjoBold";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    margin-top: 12px;
  }

  .Email_PDF_CompanyName_Copy {
    color: rgb(70, 70, 70);
    font-family: "NanumMyeongjoBold";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-top: 32px;
    margin-bottom: 12px;
  }

  .Email_PDF_CompanyHead_Copy {
    color: rgb(70, 70, 70);
    font-family: "NanumMyeongjoBold";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-right: 4px;
  }

  .Email_PDF_Chart_BG {
    width: 420px;
    height: auto;
    border-style: solid;
    border-width: 0.5px;
    border-color: rgb(70, 70, 70);
    background-color: rgb(255, 255, 255);
    margin-top: 32px;
  }

  .Email_PDF_Cell_Label {
    color: rgb(70, 70, 70);
    font-family: "NanumMyeongjoBold";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    text-align: center;

    width: 38px;
    padding: 8px;
    border: 1px solid rgb(70, 70, 70);
    background-color: rgb(242, 244, 246);
  }

  .Email_PDF_Cell_Label_2 {
    color: rgb(70, 70, 70);
    font-family: "NanumMyeongjoBold";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    text-align: center;
    word-spacing: 16px;

    width: 38px;
    padding: 8px;
    border: 1px solid rgb(70, 70, 70);
    background-color: rgb(242, 244, 246);
  }

  .Email_PDF_Cell_Label_3 {
    color: rgb(70, 70, 70);
    font-family: "NanumMyeongjoBold";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    text-align: center;
    word-spacing: 1.7px;

    width: 38px;
    padding: 8px;
    border: 1px solid rgb(70, 70, 70);
    background-color: rgb(242, 244, 246);
  }

  .Email_PDF_Cell_Body {
    color: rgb(70, 70, 70);
    font-family: "NanumMyeongjoBold";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    text-align: left;
    line-height: 150%;

    padding: 8px;
    border: 1px solid rgb(70, 70, 70);
  }

  .Email_PDF_SealNone_BG {
    display: flex;
    position: absolute;
    top: 640px;
    right: 160px;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    border-style: dashed;
    border-width: 3px;
    border-color: rgb(148, 186, 253);
    border-radius: 12px;
    background-color: rgb(255, 255, 255);
    opacity: 0.8;
    cursor: pointer;
  }

  .Email_PDF_SealDone_BG {
    display: flex;
    position: absolute;
    top: 640px;
    right: 200px;
    align-items: flex-start;
    justify-content: flex-start;

    cursor: pointer;
  }

  .Email_PDF_SealDone_Close_CT {
    display: flex;
    flex-direction: row;
    margin-left: 4px;

    border: none;
    background-color: transparent;
    padding: 0px;
  }

  .Email_PDF_Seal_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    text-align: center;
  }

  .Email_RightArea_Body_CT {
    display: flex;
    flex-direction: column;
    width: 440px;
    margin-top: 24px;
    align-items: center;
  }

  .Email_RightArea_Body_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    line-height: 150%;
  }

  .Email_RightArea_Buttons_CT {
    display: flex;
    flex-direction: row;
    margin-top: 32px;
  }
  .Email_RightArea_Buttons_CT2 {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
  }
  .Email_Left_Button_BG {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 144px;
    height: 48px;
    border-style: solid;
    border-width: 2px;
    border-color: rgb(255, 155, 155);
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
    cursor: pointer;

    color: rgb(216, 44, 44);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    text-align: center;
  }

  .Email_Right_Button_BG {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 144px;
    height: 48px;
    border-style: solid;
    border-width: 2px;
    border-color: rgb(55, 105, 252);
    border-radius: 8px;
    background-color: rgb(55, 105, 252);
    cursor: pointer;
    margin-left: 16px;

    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    text-align: center;
  }

  .Read_Labels_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .Read_Label_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
  }

  .Read_Button {
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .Read_Right_Button_BG {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 32px;
    border-style: solid;
    border-width: 2px;
    border-color: rgb(55, 105, 252);
    border-radius: 8px;
    background-color: rgb(55, 105, 252);
    cursor: pointer;
    /* margin-left: 16px; */

    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
  }

  .Read_Right_Button_Short_BG {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 32px;
    border-style: solid;
    border-width: 2px;
    border-color: rgb(231, 239, 255);
    border-radius: 8px;
    background-color: rgb(231, 239, 255);
    cursor: pointer;
    /* margin-left: 16px; */

    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
  }

  .Read_RightArea_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 96px;
    height: auto;
    width: 420px;
    margin-right: 160px;
  }

  .Read_Divider {
    display: flex;
    flex-direction: row;
    height: 1px;
    width: 100%;
    background-color: rgb(170, 170, 170);
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .Read_Item_Text_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 335px;
    margin-right: 24px;
    /* background-color: aqua; */
  }

  .Read_Item_Divider {
    display: flex;
    flex-direction: row;
    height: 1px;
    width: 100%;
    background-color: rgb(227, 227, 227);
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .Read_Item_Type_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: left;

    margin-bottom: 4px;
  }

  .Read_Item_Name_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    text-align: left;
  }

  .Read_Item_Date_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: left;

    margin-top: 8px;
  }

  .Read_Item_Positions_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: left;

    margin-top: 4px;
  }

  .Read_Item_Jobs_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: left;

    margin-top: 4px;
  }

  .Read_All_Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
  }

  .Read_Content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: auto;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .Read_Image {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 122px;
    height: 173px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  }

  .Reject_Reason_CT {
    display: flex;
    margin-top: 32px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .Reject_Reason_BG {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 500px;
    /* max-width: 360px; */
    height: auto;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(170, 170, 170);
    border-radius: 8px;
    padding: 12px;
  }

  .Reject_Reason_TextArea {
    display: flex;
    width: 95%;
    height: 240px;
    border-width: 1px;
    border-color: rgb(255, 255, 255);
    border-radius: 8px;
    padding: 12px;
    resize: none;

    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -3%;
  }

  .Reject_Reason_TextArea2 {
    display: flex;
    width: 90%;
    height: 120px;
    border-width: 1px;
    border-color: rgb(170, 170, 170);
    border-radius: 8px;
    padding: 12px;
    resize: none;
    margin-top: 12px;

    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -3%;
  }

  .Resend_Textinput {
    display: flex;
    width: 95%;
    height: 24px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(70, 70, 70);
    border-radius: 8px;
    padding: 12px;
    resize: none;
    margin-top: 12px;
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -3%;
  }

  .Reject_Reason_Divider {
    display: flex;
    flex-direction: row;
    height: 1px;
    width: 100%;
    background-color: rgb(170, 170, 170);
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .Reject_Reason_Button_BG {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 144px;
    height: 48px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(231, 239, 255);
    border-radius: 8px;
    background-color: rgb(231, 239, 255);
    cursor: pointer;
    margin-right: 12px;
    margin-bottom: 12px;

    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    text-align: center;
  }

  .Issue_Terms_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 24px;
    width: 452px;
  }

  .Issue_Resist_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 24px;
    width: 452px;
  }

  .Issue_Error_BG {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    background-color: rgb(255, 255, 255);
  }

  .Email_RightArea_Resist_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    line-height: 150%;
  }

  .Email_RightArea_Resist_Copy2 {
    color: rgb(150, 150, 150);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    line-height: 150%;
    margin-top: 12px;
  }

  .RequestList_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 84px;
  }

  .RequestList_Labels_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .RequestList_Labels_Col_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .RequestList_Label_Name {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 12px;
    /* background-color: red; */
    width: 84px;
  }

  .RequestList_Body_Name {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 12px;
    /* background-color: red; */
    width: 84px;
  }

  .RequestList_Label_Date {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 12px;
    /* background-color: rgb(104, 104, 168); */
    width: 80px;
  }

  .RequestList_Body_Date {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 12px;
    /* background-color: rgb(104, 104, 168); */
    width: 80px;
  }

  .RequestList_Label_Type {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 12px;
    /* background-color: rgb(104, 104, 168); */
    width: 32px;
  }

  .RequestList_Body_Type {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 12px;
    /* background-color: rgb(104, 104, 168); */
    width: 32px;
  }

  .RequestList_Label_CompanyName {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 12px;
    /* background-color: rgb(161, 104, 104); */
    width: 220px;
  }

  .RequestList_Body_CompanyName {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 12px;
    /* background-color: rgb(161, 104, 104); */
    width: 220px;
    word-break: break-all;
  }

  .RequestList_Body_Email {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 12px;
    /* background-color: rgb(161, 104, 104); */
    width: 210px;
    word-break: break-all;
  }

  .RequestList_Label_Email {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 12px;
    /* background-color: rgb(161, 104, 104); */
    width: 210px;
  }

  .RequestList_Body_TelNumber {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 12px;
    /* background-color: rgb(161, 104, 104); */
    width: 110px;
  }

  .RequestList_Label_TelNumber {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 12px;
    /* background-color: rgb(161, 104, 104); */
    width: 110px;
  }

  .RequestList_Label_Count {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 12px;
    /* background-color: rgb(161, 104, 104); */
    width: 54px;
  }

  .RequestList_Body_Count {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 12px;
    /* background-color: rgb(161, 104, 104); */
    width: 54px;
  }

  .RequestList_Label_Status {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-right: 12px;
    /* background-color: rgb(161, 104, 104); */
    width: 72px;
  }

  .RequestList_Body_Status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    background-color: rgb(0, 0, 0);
    width: 58px;
    height: 24px;
  }

  .RequestList_Label_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
  }

  .RequestList_Body_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
  }

  .RequestList_Label_Divider {
    display: flex;
    flex-direction: row;
    height: 1px;
    width: 92vw;
    background-color: rgb(170, 170, 170);
    margin-top: 8px;
  }

  .RequestList_Body_Divider {
    display: flex;
    flex-direction: row;
    height: 1px;
    width: 92vw;
    background-color: rgb(227, 227, 227);
    margin-top: 8px;
  }

  .RequestListCT_Contents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 92vw;
    background-color: rgb(255, 255, 255);
    margin-top: 8px;
  }

  .RequestListCT_Button_BG {
    margin: 0px;
    padding: 0px;
    width: 72px;
    height: 24px;
    text-decoration: none;
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    border-color: rgb(170, 170, 170);
    justify-content: center;
    align-items: center;
    align-content: center;
    cursor: pointer;
  }

  .RequestListCT_Button_Icon {
    display: flex;
    margin: 0px;
    padding: 0px;
    width: 24px;
    height: 24px;
    text-decoration: none;
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    border-color: rgb(170, 170, 170);
    justify-content: center;
    align-items: center;

    cursor: pointer;
  }

  .RequestListCT_Button_S {
    margin: 0px;
    padding: 0px;
    width: 36px;
    height: 24px;
    text-decoration: none;
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    border-color: rgb(170, 170, 170);
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 12px;
  }

  .RequestListCT_Button_L {
    margin: 0px;
    padding: 0px;
    width: 72px;
    height: 24px;
    text-decoration: none;
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    border-color: rgb(170, 170, 170);
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 12px;
  }

  .RequestListCT_IconButton {
    margin: 0px;
    padding: 0px;
    width: 24px;
    height: 24px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 12px;
  }

  .RequestList_Button_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
  }

  .TabBar_CT {
    display: flex;
    flex-direction: column;
    /* background-color: brown; */
    width: 75vw;
    margin-bottom: 48px;
    margin-top: 48px;
  }

  .TabBar_Labels_CT {
    display: flex;
    flex-direction: row;
    z-index: 1;
  }

  .TabBar_Label_CT {
    display: flex;
    flex-direction: row;
    z-index: 1;
    text-decoration: none;
    margin-right: 32px;
    cursor: pointer; /* 커서 모양 변경 (선택사항) */
  }

  .TabBar_Label_Underline {
    display: flex;
    z-index: 1;
    text-decoration: none;
    margin: 0px;
    padding: 0px;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .TabBar_Label_Copy_A {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -3%;
  }

  .TabBar_Label_Copy_B {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -3%;
  }

  .TabBar_Line {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* max-width: 1024px; */
    height: 2px;
    background-color: rgb(227, 227, 227);
    margin-top: 12px;
  }

  .DetailTitle_CT {
    display: flex;
    flex-direction: column;

    width: 90vw;
    max-width: 1024px;
    /* margin-bottom: 32px; */
    margin-top: 96px;
  }

  .DetailTitle_Labels_CT {
    display: flex;
    flex-direction: row;
  }

  .DetailTitle_Label_CT {
    display: flex;
    flex-direction: row;
  }

  .DetailTitle_Label_Underline {
    display: flex;
    text-decoration: none;
    background-color: transparent;
    margin: 0px;
    padding: 0px;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
  }

  .DetailTitle_Icon_CT {
    display: flex;
    flex-direction: row;
    width: 24px;
    height: 24px;
  }

  .DetailTitle_Icon_Profile_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-left: 4px;
    /* margin-bottom: 4px; */
  }

  .DetailTitle_Label_Copy_A {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -3%;
  }

  .DetailTitle_Label_Copy_B {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -3%;
  }

  .DetailTitle_Line {
    display: flex;
    flex-direction: row;
    width: 75vw;
    /* max-width: 1024px; */
    height: 2px;
    background-color: rgb(227, 227, 227);
    margin-top: 12px;
  }

  .ListCard_BG {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    width: 75vw;
    /* max-width: 1024px; */
    height: 64px;
    border-style: solid;
    border-radius: 12px;
    border-width: 1px;
    border-color: rgb(148, 186, 253);
    background-color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .ListCard_Box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .ListCard_Label_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -3%;
  }

  .ListCard_Body_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -3%;

    margin-top: 4px;
  }

  .ListCard_Box_CT {
    display: flex;
    margin-right: 64px;
  }

  .ListCard_Box_CT_First {
    display: flex;
    margin-left: 32px;
    margin-right: 64px;
  }

  .ListCard_Box_CT_Last {
    display: flex;
    margin-right: 32px;
  }

  .ListCard_LeftContents {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .ListCard_RightContents {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .Wait_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75vw;
  }

  .Wait_Lists_CT {
    display: flex;
    flex-direction: column;
    height: 656px;
  }

  .Wait_NoneItem_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    margin-top: 120px;
    margin-bottom: 584px;
    width: 100%;
    text-align: center;
  }

  .Wait_Notification_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    margin-top: 360px;
    margin-bottom: 346px;
  }

  .DetailWait_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1024px;
  }

  .Wait_Pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .Wait_Pagination_Active_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    height: 32px;
  }

  .Wait_Pagination_Inactive_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    height: 32px;
    padding: 0px;
  }

  .Wait_Pagination_Style {
    display: flex;
    list-style-type: none;
    float: left;
    margin-left: 12px;
    margin-right: 12px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    height: 37px;
  }

  .Wait_PaginationCT_Style {
    display: flex;
    list-style-type: none;
    float: left;
    margin-left: 12px;
    margin-right: 12px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    height: 32px;
  }

  .Detail_Title_BG {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 75vw;
    background-color: rgb(255, 255, 255);
    height: 64px;
  }

  .Detail_Title_Contents {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .Detail_Title_LeftContents {
    display: flex;
    flex-direction: row;
    margin-left: 24px;
  }

  .Detail_Title_RightContents {
    display: flex;
    flex-direction: row;
    margin-right: 24px;
  }

  .Detail_Title_ContentBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 48px;
  }

  .Detail_Title_ContentBox_Last {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .Detail_Title_Label_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: -3%;
    margin-bottom: 4px;
  }

  .Detail_Title_Content_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: -3%;
  }

  .CertificateCard_BG {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(227, 227, 227);
    border-radius: 16px;
    width: 480px;
    height: auto;
    background-color: rgb(255, 255, 255);
    margin-top: 48px;
  }

  .CertificateCard_BG_ResignerDetail {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 640px;
    height: auto;
    background-color: rgb(255, 255, 255);
    margin-bottom: 64px;
  }

  .CertificateCard_BG_Complete {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(227, 227, 227);
    border-radius: 16px;
    width: 480px;
    height: auto;
    background-color: rgb(255, 255, 255);
    margin-top: 48px;
  }

  .CertificateCard_BG_Reject {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(227, 227, 227);
    border-radius: 16px;
    width: 480px;
    height: auto;
    background-color: rgb(255, 255, 255);
    margin-top: 48px;
  }

  .CertificateCard_Content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-top: 16px;
  }

  .CertificateCard_Body_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .CertificateCard_SecondText_CT {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .CertificateCard_Label_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    letter-spacing: -3%;
    margin-left: 24px;
  }

  .CertificateCard_BodyLeft {
    display: flex;
    flex-direction: row;
  }

  .CertificateCard_BodyLeft_C {
    display: flex;
    flex-direction: column;
  }

  .CertificateCard_Body_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: -3%;
    margin-left: 24px;
    height: 32px;
    display: flex;
    align-items: center;
  }

  .CertificateCard_SecondBody_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: -3%;
    margin-left: 24px;
    height: auto;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }

  .CertificateCard_DetailBody_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    letter-spacing: -3%;
    margin-left: 8px;
    height: 32px;
    display: flex;
    align-items: center;
  }

  .CertificateCard_Icon_CT {
    display: flex;
    flex-direction: column;
    margin-right: 24px;
    cursor: pointer;
  }

  .CertificateCard_Line {
    display: flex;
    flex-direction: row;
    width: 90%;
    height: 1px;
    background-color: rgb(227, 227, 227);
  }

  .DetailWait_Button_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 480px;
    margin-top: 32px;
    margin-bottom: 64px;
  }

  .DetailWait_Button_One_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 32px;
    margin-bottom: 64px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    padding: 0px;
  }

  .PrimayButton_One_BG {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    width: 480px;
    height: 64px;
    background-color: rgb(55, 105, 252);
    cursor: pointer;
    border: none;
  }

  .PrimayButton_BG {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    width: 228px;
    height: 64px;
    background-color: rgb(55, 105, 252);
    cursor: pointer;
  }

  .SubButton_BG {
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-radius: 12px;
    border-color: rgb(55, 105, 252);
    border-width: 1px;
    width: 228px;
    height: 64px;
    background-color: rgb(255, 255, 255);
    cursor: pointer;

    margin-top: 24px;
    margin-right: 24px;
  }

  .DestroyButton_BG {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    width: 228px;
    height: 64px;
    background-color: rgb(255, 255, 255);
    border-style: solid;
    border-width: 1px;
    border-color: rgb(255, 115, 115);
    cursor: pointer;
  }

  .PrimayButton_Copy {
    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
  }

  .SubButton_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
  }

  .DestroyButton_Copy {
    color: rgb(216, 44, 44);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
  }

  .RejectInputBox_BG {
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(227, 227, 227);
    border-radius: 12px;
    width: 446px;
    height: 400px;
    background-color: rgb(255, 255, 255);
    margin-top: 48px;
    padding: 16px;
    resize: none;
    outline: none;
  }

  .RejectReason_CT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Reject_Content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .RejectReason_Label_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -3%;

    display: flex;
    margin-bottom: 8px;
    margin-top: 32px;
    width: 480px;
  }

  .RejectReason_Body_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 130%;

    display: flex;
    margin-bottom: 64px;
    width: 480px;
  }

  .Profile_Content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 640px;
    margin-top: 48px;
  }

  .Profile_Subject {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 12px;
    width: 640px;
  }

  .Profile_Subject_Row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 12px;
    width: 640px;
  }

  .Profile_Subject_Last {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 640px;
  }

  .ProfileDetail_Subject {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 24px;
    width: 480px;
    height: auto;
  }

  .ProfileDetail_Subject_TBD {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 24px;
    width: 572px;
    height: auto;
  }

  .ProfileDetail_Subject_Last {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 452px;
  }

  .Profile_Label_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 8px;
    width: 452px;
  }

  .Profile_Label_Preview_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
  }

  .Profile_Label_Icon_Logo_CT {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding: 0px;
  }

  .Profile_Label_Icon_License_CT {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    cursor: pointer;
  }

  .Profile_Label_Icon_Seal_CT {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding: 0px;
  }

  .Profile_Label_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  }

  .Input_Label_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
  }

  .Profile_Preview_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-decoration: underline;
    text-decoration-color: rgb(55, 105, 252);
    border: none;
    background-color: transparent;
    padding: 0px;
    margin: 0px;
    cursor: pointer;
  }

  .Profile_Body_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    width: 640px;
  }

  .Profile_NoImage_Copy {
    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  }

  .Profile_Placeholder_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
  }

  .Profile_Line {
    display: flex;
    flex-direction: row;
    width: 640px;
    height: 2px;
    background-color: rgb(227, 227, 227);
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .Profile_CloseModal_Button_CT {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    cursor: pointer;
  }

  .Profile_CloseModal_Button {
    margin: 0px;
    padding: 0px;
    border: none;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
  }

  .Profile_CloseModal_Contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }

  .Profile_Investor_Document_Button_BG {
    border-style: none;
    background-color: transparent;
    margin: 0px;
    padding: 0px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    cursor: pointer;
  }

  .Profile_Investor_Document_Button_Copy {
    color: #3769fc;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  }

  .ProfileDetail_ShortInput_BG {
    display: flex;
    flex-direction: row;
    width: 326px;
    height: 40px;
    padding: 0px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);

    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    padding-left: 8px;
    padding-right: 8px;

    outline: none;
  }

  .ProfileDetail_LongInput_BG {
    display: flex;
    flex-direction: row;
    width: 624px;
    height: 40px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);

    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    padding-left: 8px;
    padding-right: 8px;
  }

  .ProfileDetail_LongInput_BG_Icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 640px;
    height: 40px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
  }

  .ProfileDetail_LongInput_None {
    display: flex;
    flex-direction: row;
    border: none;
    width: 624px;
    height: 40px;
    background-color: transparent;
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    padding-left: 8px;
    padding-right: 8px;

    outline: none;
  }

  .ProfileDetail_AddressDetail_CT {
    display: flex;
    align-items: center;
    margin-top: 12px;
  }

  .ProfileDetail_TextArea_BG {
    display: flex;
    flex-direction: row;
    width: 624px;
    height: 96px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);

    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 8px;
    padding-right: 8px;
    resize: none;
  }

  .ProfileDetail_UploadButton_CT {
    display: flex;
    justify-content: row;
    margin-bottom: 12px;
  }

  .ProfileDetail_UploadButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 96px;
    height: 42px;
    border-radius: 8px;
    border-width: 0px;
    background-color: rgb(231, 239, 255);

    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    margin-left: 12px;
    cursor: pointer;
    padding: 0px;
  }

  .ProfileDetail_UploadButton_None {
    display: none;
  }

  .ProfileDetail_Image {
    width: 120px;
  }

  .Seal_Image_Modal {
    width: auto;
    max-height: 640px;
  }

  .ImgModal_CT {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    padding-top: 240px;
    width: 100vw;
    height: auto;
  }

  .ImgModal_Button {
    display: flex;
    flex-direction: column;
    width: 32px;
    height: 32px;
    margin-left: 8px;
    cursor: pointer;
  }

  .Icon_CT {
    display: flex;
    flex-direction: column;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    cursor: pointer;
  }

  .PdfModal_CT {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    padding-top: 240px;
    width: 100vw;
    height: auto;
  }

  .PdfModal_Close {
    display: flex;
    flex-direction: column;
    width: 32px;
    height: 32px;
    margin-left: 8px;
    cursor: pointer;
  }

  .Footer_CT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 96px;
    margin-bottom: 24px;
    /* margin-left: 20vw; */
    width: 75vw;
    /* max-width: 1024px; */
  }

  .Footer_Contents_CT {
    display: flex;
    flex-direction: row;
    margin-top: 24px;
  }

  .Footer_Contents_CT_V {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 12px;
  }

  .Footer_Divider {
    display: flex;
    flex-direction: row;
    height: 1px;
    width: 75vw;
    background-color: rgb(170, 170, 170);
    /* max-width: 1024px; */
  }

  .Footer_Button {
    display: flex;
    margin: 0px;
    padding: 0px;
    border: none;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
  }

  .Footer_Logo {
    display: flex;
    width: 79.5px;
    height: 18px;
  }

  .Footer_Texts {
    display: flex;
    flex-direction: column;
    margin-left: 48px;
  }

  .Footer_Texts_V {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
  }

  .Footer_Text {
    display: flex;
    margin-bottom: 4px;
  }

  .Footer_Text_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -0.4px;
    /* justify-content: center; */
  }

  .Signin_Modal_BG {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Profile_Modal_BG {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 48px;
  }

  .Modal_Contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .Modal_Icon {
    display: flex;
    flex-direction: column;
    width: 32px;
    height: 32px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    text-decoration: none;
  }

  .Signin_Password_Contents {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .Signin_PasswordInvalid_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -3%;
    margin-top: 8px;
  }

  .Signin_Error_Copy {
    color: rgb(255, 115, 115);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -3%;
    margin-top: 8px;
  }

  .Signin_Match_Copy {
    color: rgb(3, 166, 0);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -3%;
    margin-top: 8px;
  }

  .Signin_Guide {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 452px;
  }

  .Signin_Guide_TextButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
    padding: 0px;
    margin: 0px;
    /* text-decoration: none; */
    cursor: pointer;

    margin-top: 8px;
  }

  .Signin_Guide_TextButton_Icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
  }

  .Signin_Guide_TextButton_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -3%;
  }

  .Signin_IdEmail_Num {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .TermLists_BG {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 452px;
    height: auto;

    border-style: solid;
    border-width: 1px;
    border-color: rgb(227, 227, 227);
    border-radius: 8px;

    padding-bottom: 8px;
  }

  .TermLists_Short_BG {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 266px;
    height: auto;

    border-style: solid;
    border-width: 1px;
    border-color: rgb(227, 227, 227);
    border-radius: 8px;

    padding-bottom: 8px;
  }

  .TermLists_Line {
    width: 432px;
    height: 1px;
    background-color: rgb(227, 227, 227);
  }

  .TermLists_Short_Line {
    width: 240px;
    height: 1px;
    margin-bottom: 12px;
    background-color: rgb(227, 227, 227);
  }

  .TermList_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 432px;
    height: 32px;
  }

  .TermList_Two_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 432px;
    height: 48px;
  }

  .TermList_Short_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 240px;
    height: 32px;
  }

  .TermList_Two_Short_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 240px;
    height: 48px;
  }

  .TermList_Left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .TermList_Right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .TermList_Default_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -3%;
  }

  .TermList_Optional_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -3%;
    margin-right: 2px;
  }

  .TermList_TwoLine_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -3%;
    line-height: 130%;
  }

  .TermList_Red_Copy {
    color: rgb(255, 115, 115);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -3%;
    margin-right: 2px;
  }

  .TermList_Detail_CT {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: 8px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding: 0px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .TermList_Detail_Icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
  }

  .TermList_Detail_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -3%;
  }

  .TermList_CheckBox_CT {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 0px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .Read_AllContent_Between {
    display: flex;
    flex: 1;
    height: auto;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 48px;
    padding-left: 4vw;
    padding-right: 4vw;
    background-color: rgb(255, 255, 255);
  }

  .LadingData_Copy {
    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    margin-top: 48px;
  }

  .Memo_Title_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    margin-bottom: 24px;
  }

  .Leave_Guide_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    line-height: 150%;
  }

  .Leave_Image_CT {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 64px;
    width: auto;
  }

  .Leave_TextButton_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 72px;
    width: 100%;
    max-width: 600px;
  }

  .Leave_TextButton_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    letter-spacing: -3%;

    padding: 0px;
    border-style: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: transparent;
  }

  .Leave_IconTextButton_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .Leave_LeftArea_CT {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 54vw;
    /* background-color: aquamarine; */
  }

  .Leave_InputField_CT {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 480px;
    margin-top: 48px;
  }

  .Leave_InputField_CT_TBD {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 590px;
  }

  .Leave_Main_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    letter-spacing: -3%;
    line-height: 150%;
  }

  .Leave_Sub_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: -3%;
    line-height: 150%;

    margin-top: 24px;
    max-width: 640px;
  }

  .Leave_LongInput_BG {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 462px;
    height: 48px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding-left: 8px;
    padding-right: 8px;

    outline: none;
  }

  .Leave_LongInput_BG_TBD {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 574px;
    height: 36px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    outline: none;
  }

  .Leave_Vertical_Divider {
    width: 1px;
    height: 90%;
    min-height: 640px;
    background-color: rgb(227, 227, 227);
  }

  .Leave_RightArea_CT {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    max-width: 242px;
    height: 90%;
    min-height: 640px;
    /* background-color: rgb(245, 255, 140); */
  }

  .Leave_PageCount_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    letter-spacing: -3%;
  }

  .Leave_Messege_BG {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 360px;
    /* height: 356px; */
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);
    padding: 24px;
  }

  .Leave_Messege_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -3%;
  }

  .Leave_Messege_Label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .Leave_Navigation_CT {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;
  }

  .ResignerDetail_Contents_CT {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    margin-top: 48px;
    margin-bottom: 192px;
  }

  .ResignerDetail_Contents_Right_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    /* margin-top: 48px; */
  }

  .ResignerDetail_Input_BG {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 19vw;
    max-width: 326px;
    height: 44px;
    border-radius: 8px;
    border-style: none;
    background-color: rgb(242, 244, 246);
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding-left: 12px;
    padding-right: 12px;

    outline: none;
    white-space: nowrap;
    overflow: hidden;
  }

  .ResignerDetail_PDF_CT {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .ResignerDetail_Input_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 12px;
  }

  .ResignerDetail_Vertical_Divider {
    width: 1px;
    height: 100%;
    background-color: rgb(227, 227, 227);
    /* margin-top: 48px;
    margin-bottom: 64px; */
    margin-left: 48px;
    margin-right: 48px;
  }

  .ResignerDetail_Title_CT {
    display: flex;
    flex-direction: column;

    width: 75vw;
    /* max-width: 1024px; */
    /* margin-bottom: 32px; */
    margin-top: 48px;
    margin-left: 20vw;
  }

  .EmployeeResist_ExcelResult_CT {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 400px;
    max-width: 1248px;
    overflow-x: auto;
    white-space: nowrap;
  }

  .EmployeeResist_ExcelResult_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    /* margin-right: 48px; */
    margin-bottom: 8px;
  }

  .EmployeeResist_LeftArea_CT {
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start;
    align-items: flex-start; */
    width: 100%;
    /* margin-bottom: 24px; */
    max-width: 688px;
    /* background-color: aquamarine; */
  }

  .EmployeeResist_RightArea_CT {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    /* margin-bottom: 24px; */
    max-width: 242px;
    /* background-color: aquamarine; */
  }

  .EmployeeResist_Vertical_Divider {
    width: 1px;
    height: 100%;
    background-color: rgb(227, 227, 227);
  }

  .EmployeeResist_Divider {
    display: flex;
    flex-direction: row;
    height: 1px;
    width: 120px;
    background-color: rgb(170, 170, 170);
    margin-bottom: 8px;
    /* max-width: 1024px; */
  }

  .EmployeeResist_Divider_V {
    display: flex;
    height: 360px;
    width: 1px;
    background-color: rgb(227, 227, 227);
    margin-left: 24px;
    /* max-width: 1024px; */
  }

  .EmployeeResist_Input_BG {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 102px;
    height: 40px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding-left: 8px;
    padding-right: 8px;

    outline: none;
  }

  .EmployeeResist_Example_CT {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 480px;
    margin-top: 48px;
  }

  .EmployeeResist_Text_CT {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 480px;
    margin-bottom: 12px;
  }

  .EmployeeResist_Example_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    /* margin-bottom: 8px; */
  }

  .EmployeeResist_Example_Image {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 480px;
  }

  .EmployeeResist_Label_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    width: 480px;
  }

  .EmployeeResist_Button {
    padding: 0px;
    margin: 0px;
    border-style: none;
    cursor: pointer;
    background-color: transparent;
  }

  .EmployeeResist_Delete_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* margin-bottom: 8px; */
  }

  .EmployeeResist_NewLabelButton_BG {
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: none;
    border-radius: 8px;
    width: 480px;
    height: 64px;
    background-color: rgb(55, 105, 252);
    cursor: pointer;
    margin-bottom: 64px;
    margin-top: 12px;

    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
  }

  .EmployeeResist_NewLabel_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    letter-spacing: -3%;
    line-height: 130%;
    margin-bottom: 4px;
  }

  .EmployeeDetail_Title_Content_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: -3%;
  }

  .CheckList_Label_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -3%;
    margin-right: 4px;
  }

  .CheckList_Label_Copy_TBD {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -3%;
    margin-right: 2px;
  }

  .CheckList_Label_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .CheckList_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 480px;
    height: 40px;
  }

  .CheckList_CT_TBD {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 590px;
    height: 40px;
  }

  .CheckList_Divider {
    display: flex;
    flex-direction: row;
    height: 1px;
    width: 592px;
    background-color: rgb(170, 170, 170);
  }

  .CheckList_Divider_TBD {
    display: flex;
    flex-direction: row;
    height: 1px;
    width: 590px;
    background-color: rgb(170, 170, 170);
  }

  .CheckList_Comp_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 480px;
    height: auto;
  }

  .CheckList_Comp_CT_TBD {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 590px;
    height: auto;
  }

  .CheckList_InfoModal_CT {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    border-radius: 12px;
    padding-left: 24px;
    padding-right: 24px;
    width: 432px;
  }

  .CheckList_InfoModal_Text_CT {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 24px;
  }

  .CheckList_InfoModal_Title_CT {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 432px;
    margin-bottom: 12px;
  }

  .CheckList_InfoModal_Icon_CT {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
  }

  .CheckList_InfoModal_Title_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -3%;
    margin-top: 24px;
  }

  .CheckList_InfoModal_Body_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -3%;
    margin-top: 12px;
    line-height: 150%;
  }

  .CheckList_InfoModal_Divider {
    display: flex;
    flex-direction: row;
    height: 1px;
    width: 432px;
    background-color: rgb(170, 170, 170);
  }

  .CheckList_Close_Button {
    margin: 0px;
    padding: 0px;
    border: none;
    background-color: transparent;
    text-decoration: none;
    cursor: pointer;
  }

  .CheckList_InfoModal_Warning_CT {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 16px;
  }

  .ContractInput_Label_BG {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 338px;
    height: 44px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);

    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    padding-left: 16px;
    padding-right: 16px;

    outline: none;
  }

  .ContractInput_Label_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: -3%;
  }

  .ContractInput_Button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 96px;
    height: 44px;
    border-radius: 8px;
    border-width: 1px;
    border-color: rgb(55, 105, 252);
    background-color: rgb(255, 255, 255);

    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    margin-left: 12px;
    cursor: pointer;
    padding: 0px;
  }

  .ContractInput_Modal_CT {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    border-radius: 12px;
    padding-left: 24px;
    padding-right: 24px;
    width: 888px;
  }

  .ContractInput_Close_CT {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 888px;
    margin-bottom: 12px;
  }

  .ContractInput_Contents_CT {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 888px;
    margin-bottom: 24px;
  }

  .ContractInput_Left_CT::-webkit-scrollbar {
    display: none;
  }

  .ContractInput_Left_CT {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 420px;
    height: 636px;
    background-color: rgb(255, 255, 255);
    overflow-x: scroll;
  }

  .ContractInput_Left_Content_CT {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .ContractInput_Right_CT::-webkit-scrollbar {
    display: none;
  }

  .ContractInput_Right_CT {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 420px;
    height: 636px;
    background-color: rgb(255, 255, 255);
    overflow-x: scroll;
  }

  .ContractInput_Divider {
    display: flex;
    flex-direction: row;
    height: 1px;
    width: 420px;
    margin-top: 12px;
    margin-bottom: 12px;
    background-color: rgb(170, 170, 170);
  }

  .ContractInput_VerticalDivider {
    display: flex;
    flex-direction: row;
    height: 636px;
    width: 1px;
    background-color: rgb(170, 170, 170);
  }

  .ContractInput_Chart_BG {
    width: 420px;
    height: auto;
    border-style: solid;
    border-width: 0.5px;
    border-color: rgb(70, 70, 70);
    background-color: rgb(255, 255, 255);
  }

  .ContractInput_Contract_Title_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: -3%;
    margin-top: 48px;
  }

  .ContractInput_Cell_Label {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;

    width: 44px;
    padding: 6px;
    border: 1px solid rgb(70, 70, 70);
    background-color: rgb(242, 244, 246);
  }

  .ContractInput_Cell_Label_2 {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    word-spacing: 16px;

    width: 42px;
    padding: 6px;
    border: 1px solid rgb(70, 70, 70);
    background-color: rgb(242, 244, 246);
  }

  .ContractInput_Cell_Label_3 {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    word-spacing: 1.7px;

    width: 42px;
    padding: 6px;
    border: 1px solid rgb(70, 70, 70);
    background-color: rgb(242, 244, 246);
  }

  .ContractInput_Cell_Full_Body {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: left;
    line-height: 150%;
    width: 240px;
    padding: 6px;
    border: 1px solid rgb(70, 70, 70);
  }
  .ContractInput_Cell_Full_Body {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: left;
    line-height: 150%;
    width: 240px;
    padding: 6px;
    border: 1px solid rgb(70, 70, 70);
  }

  .ContractInput_Cell_Half_Body {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: left;
    line-height: 150%;
    width: 121px;
    padding: 6px;
    border: 1px solid rgb(70, 70, 70);
  }

  .ContractInput_Body_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    line-height: 150%;
  }

  .ContractInput_WidthTitle_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    width: 420px;
  }

  .ContractInput_WidthBody_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    width: 420px;
    margin-bottom: 8px;
    word-break: break-word;
  }

  .ContractInput_SubBody_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: left;
    line-height: 150%;

    margin-top: 4px;
    width: 95%;
    margin-left: 4px;
  }

  .ContractInput_Date_Copy_CT {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 420px;
    margin-top: 32px;
  }

  .ContractInput_Date_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
  }

  .ContractInput_InputField {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 24px;
    width: 420px;
    height: auto;
  }

  .ContractInput_InputField_Label_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 8px;
    width: 420px;
  }

  .ContractInput_LongInput_BG {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 402px;
    height: 40px;

    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding-left: 8px;
    padding-right: 8px;

    outline: none;
  }

  .ContractInput_ShortInput_BG {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 40px;

    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding-left: 8px;
    padding-right: 8px;

    outline: none;
  }

  .ContractInput_Guide_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;

    margin-left: 8px;
  }

  .ContractInput_Dot_BG {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-style: none;
    border-radius: 2px;
    width: 4px;
    height: 4px;
    background-color: rgb(70, 70, 70);
    margin-top: 11px;
    margin-left: 4px;
  }

  .ContractInput_SubBody_CT {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .SalaryCompositionTable_Input_BG_Title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 72px;
    border-style: none;
    background-color: rgb(255, 255, 255);
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    padding: 0px;
    outline: none;
  }

  .SalaryCompositionTable_Input_BG_Title_Required {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 72px;
    border-style: none;
    background-color: rgb(255, 255, 255);
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    padding: 0px;
    outline: none;
  }

  .SalaryCompositionTable_Input_BG_Amount {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 72px;
    border-style: none;
    background-color: rgb(255, 255, 255);
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    padding: 0px;
    outline: none;
  }

  .SalaryCompositionTable_Input_BG_Amount_Required {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 72px;
    border-style: none;
    background-color: rgb(255, 255, 255);
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    padding: 0px;
    outline: none;
  }

  .SalaryCompositionTable_Input_BG_Standard {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 158px;
    border-style: none;
    background-color: rgb(255, 255, 255);
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    padding: 0px;
    outline: none;
  }

  .SalaryCompositionTable_Input_BG_Standard_Required {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 158px;
    border-style: none;
    background-color: rgb(255, 255, 255);
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    padding: 0px;
    outline: none;
  }

  .SalaryCompositionTable_Input_BG_Note {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 64px;
    border-style: none;
    background-color: rgb(255, 255, 255);
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    padding: 0px;
    outline: none;
  }

  .SalaryCompositionTable_Cell {
    padding: 6px;
    border: 1px solid rgb(70, 70, 70);
    background-color: rgb(255, 255, 255);
  }

  .SalaryCompositionTable_Cell_Label {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;

    padding: 6px;
    border: 1px solid rgb(70, 70, 70);
    background-color: rgb(242, 244, 246);
  }

  .WorkScheduleTable_Cell_Label {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    width: 48px;
    padding: 6px;
    border: 1px solid rgb(70, 70, 70);
    background-color: rgb(242, 244, 246);
  }

  .WorkScheduleTable_Cell {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;

    padding: 6px;
    border: 1px solid rgb(70, 70, 70);
    background-color: rgb(255, 255, 255);
  }

  .WorkScheduleTable_Input_BG {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 56px;
    border-style: none;
    background-color: rgb(255, 255, 255);
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    padding: 0px;
    outline: none;
  }

  .WorkScheduleTable_Required_Input_BG {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 56px;
    border-style: none;
    background-color: rgb(255, 255, 255);
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    padding: 0px;
    outline: none;
  }

  .PaymentIntro_Title_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    letter-spacing: -3%;
    line-height: 150%;
    text-align: center;
    margin-top: 96px;
  }

  .PaymentIntro_Info_BG {
    width: 280px;
    /* height: 460px; */
    border-style: solid;
    border-width: 1px;
    border-radius: 24px;
    border-color: rgb(148, 186, 253);

    padding-left: 32px;
    padding-right: 32px;
    padding-top: 24px;
    padding-bottom: 24px;

    margin-top: 32px;
  }

  .PaymentIntro_Info_Divider {
    width: 280px;
    height: 1px;
    background-color: rgb(148, 186, 253);

    margin-top: 12px;
    margin-bottom: 24px;
  }

  .PaymentIntro_Info_CT {
    display: flex;
    flex-direction: column;

    margin-bottom: 20px;
  }

  .PaymentIntro_Info_Label_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 4px;
  }

  .PaymentIntro_Info_Body_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }

  .PaymentAct_BG {
    max-width: 1440px;
    border-style: solid;
    border-width: 1px;
    border-radius: 24px;
    border-color: rgb(148, 186, 253);

    padding-left: 32px;
    padding-right: 32px;
  }

  .box_section {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgb(0 0 0 / 1%), 0 6px 6px rgb(0 0 0 / 6%);
    padding: 50px 50px 50px 50px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    color: #333d4b;
    align-items: center;
    text-align: center;
    overflow-x: auto; /* Add this property for horizontal scrolling */
    white-space: nowrap; /* Prevent text wrapping */
  }

  .button {
    color: #f9fafb;
    background-color: #3182f6;
    margin: 30px 15px 0px 15px;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    white-space: nowrap;
    text-align: center;
    /* vertical-align: middle; */
    cursor: pointer;
    border: 0 solid transparent;
    user-select: none;
    transition: background 0.2s ease, color 0.1s ease;
    text-decoration: none;
    border-radius: 7px;
    padding: 11px 16px;
    width: 250px;
  }

  .box_section2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 540px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgb(0 0 0 / 1%), 0 6px 6px rgb(0 0 0 / 6%);
    padding: 48px 48px 48px 48px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    /* color: #333d4b; */
    /* align-items: center; */
    /* text-align: left; */
    overflow-x: auto; /* Add this property for horizontal scrolling */
    white-space: nowrap; /* Prevent text wrapping */
  }

  .PaymentConfirm_Category_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .PaymentConfirm_Left_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }

  .PaymentConfirm_Label_Copy {
    color: #3182f6;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 4px;
  }

  .NewEmployee_Messege_BG {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 480px;
    height: auto;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);
  }

  .ContractInput_ConfirmButton_BG {
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: none;
    border-radius: 8px;
    width: 420px;
    height: 48px;
    background-color: rgb(55, 105, 252);
    cursor: pointer;
    margin-bottom: 24px;
    margin-top: 12px;
    padding-top: 12px;
    padding-bottom: 12px;

    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
  }

  .Payment_Terms_Modal_CT {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    background-color: white;
    border-radius: 12px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    width: 640px;
  }

  .Payment_Terms_Close_CT {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 640px;
    margin-bottom: 12px;
  }

  .Payment_Terms_Label_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
  }
  .Payment_Terms_Body_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: left;
    line-height: 135%;
    margin-top: 8px;
  }

  .CompanyProfile_PaymentCancel_Button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: none;
    border-radius: 8px;
    width: 84px;
    height: 40px;
    background-color: rgb(255, 233, 233);
    cursor: pointer;

    padding-top: 12px;
    padding-bottom: 12px;

    color: rgb(216, 44, 44);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
  }

  .PaymentRefund_Info_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    /* margin-top: 12px; */
  }

  .Document_EmployeeList_Label_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  }

  .Document_EmployeeList_Body_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -3%;
    text-align: left;
  }

  .Document_EmployeeList_Label_CT {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 100%;
    /* max-width: 780px; */
    margin-bottom: 8px;
  }

  .Document_EmployeeList_Contents_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    /* background-color: antiquewhite; */
    width: 100%;
    max-width: 780px;
    min-height: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .Document_EmployeeList_Divider {
    width: 100%;
    height: 1px;
    background-color: rgb(227, 227, 227);
  }

  .Document_EmployeeList_Button_BG {
    display: flex;
    flex-direction: column;
    border-style: none;
    background-color: rgb(255, 255, 255);
    cursor: pointer;
    width: 100%;
    max-width: 780px;
    margin: 0px;
    padding: 0px;
  }

  .Document_EmployeeList_Button_BG:hover {
    background-color: rgb(55, 105, 252);
    transition: 0.7s;
  }

  .Document_EmployeeList_Button_BG:hover .Document_EmployeeList_Body_Copy {
    color: white;
  }

  .Document_Box_BG {
    width: 394px;
    padding: 12px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(170, 170, 170);

    margin-top: 8px;
    margin-bottom: 12px;
  }

  .Document_Box_Label_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
  }

  .PartnershipAgreement_TableInput {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-style: none;
    background-color: rgb(255, 255, 255);
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding: 0px;
    outline: none;
  }

  .ReferCheckList_CT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 48px;
    width: 100%;
  }

  .ReferCheckList_Label_CT {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin-bottom: 12px;
  }

  .ReferCheckList_CheckBox_CT {
    margin-right: 24px;
  }

  .ReferCheckList_Label_Name_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    min-width: 84px;
    max-width: 84px;
    margin-right: 24px;
  }

  .ReferCheckList_Label_YYYYMMDD_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    min-width: 84px;
    max-width: 84px;
    margin-right: 24px;
  }

  .ReferCheckList_Label_Department_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    width: 100%;
    margin-right: 24px;
  }

  .ReferCheckList_Label_IssueDate_Copy {
    min-width: 176px;
    max-width: 176px;
    margin-right: 24px;

    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -3%;
    text-align: left;
  }

  .ReferCheckList_BodyList_CT {
    display: flex;
    flex-direction: column;
    /* height: 580px; */
  }

  .ReferCheckList_Body_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    min-height: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .ReferCheckList_Body_Name_Copy {
    min-width: 72px;
    max-width: 72px;
    margin-right: 24px;

    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -3%;
    text-align: left;
  }

  .ReferCheckList_Body_YYYYMMDD_Copy {
    min-width: 84px;
    max-width: 84px;
    margin-right: 24px;

    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -3%;
    text-align: left;
  }

  .ReferCheckList_Body_DetailData_Button {
    min-width: 60px;
    max-width: 60px;
    background-color: transparent;
    border-style: none;
    padding: 0px;
    margin-left: 32px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -3%;
    text-align: left;
    text-decoration: underline;
    cursor: pointer;
  }

  .ReferCheckList_Body_Department_Copy {
    width: 100%;
    margin-right: 24px;

    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -3%;
    text-align: left;
  }

  .IssueDocument_DocumentList_Label_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -3%;
    text-align: left;
    margin-bottom: 12px;
  }

  .IssueDocument_DocumentList_CT {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 592px;
  }

  .ConsentDocumentDetail_DocumentPreview_BG {
    width: 100%;
    height: 720px;
    background-color: rgb(242, 244, 246);
  }

  .ReferEmployee_Lists_CT {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 656px;
  }

  .ReferSelectList_EmployeeList_Contents_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    min-height: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .ReferSelectList_EmployeeList_Button_BG {
    display: flex;
    flex-direction: column;
    border-style: none;
    background-color: rgb(255, 255, 255);
    cursor: pointer;
    width: 100%;
    margin: 0px;
    padding: 0px;
  }

  .ReferSelectList_Label_DocumentStatus_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    min-width: 64px;
    max-width: 64px;
    margin-right: 24px;
  }

  .ReferSelectList_Label_DocumentName_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    width: 100%;
    margin-right: 24px;
  }

  .ReferSelectList_Label_Name_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    min-width: 84px;
    max-width: 84px;
    margin-right: 24px;
  }

  .ReferSelectList_Label_InvestorCompanyShareholderReport_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    width: 100%;
    min-width: 84px;
    /* max-width: 84px; */
    margin-right: 24px;
  }

  .ReferSelectList_Label_TelNumber_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    min-width: 128px;
    max-width: 128px;
    margin-right: 24px;
  }

  .ReferSelectList_Label_Department_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    width: 100%;
    margin-right: 24px;
  }

  .ReferSelectList_Label_JobPosition_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    max-width: 120px;
    min-width: 120px;
    margin-right: 24px;
  }

  .ReferSelectList_Label_WorkPeriod_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    max-width: 192px;
    min-width: 192px;
    margin-right: 24px;
    /* background-color: aqua; */
  }

  .ReferSelectList_Label_JobPosition_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    max-width: 120px;
    min-width: 120px;
    margin-right: 24px;
  }

  .ReferSelectList_Label_AcqDate_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    max-width: 84px;
    min-width: 84px;
  }

  .ReferSelectList_Body_DocumentStatus_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: left;
    min-width: 64px;
    max-width: 64px;
    margin-right: 24px;
  }

  .ReferSelectList_Body_DocumentName_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    /* width: 286px; */
    /* width: 720px; */
    width: 100%;
    margin-right: 24px;
    text-align: left;
    /* background-color: aqua; */
  }

  .ReferSelectList_Body_InvestorCompanyShareholderReport_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    width: 100%;
    min-width: 84px;
    margin-right: 24px;
    text-align: left;
  }

  .ReferSelectList_Body_Name_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: left;
    min-width: 84px;
    max-width: 84px;
    margin-right: 24px;
  }

  .ReferSelectList_Body_YYYYMMDDDate_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: left;
    min-width: 86px;
    max-width: 86px;
    margin-right: 24px;
  }

  .ReferSelectList_Body_TelNumber_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: left;
    min-width: 128px;
    max-width: 128px;
    margin-right: 24px;
  }

  .ReferSelectList_Body_Department_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: left;
    width: 100%;
    margin-right: 24px;
  }

  .ReferSelectList_Body_WorkPeriod_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: left;
    min-width: 192px;
    max-width: 192px;
    margin-right: 24px;
  }

  .ReferSelectList_Body_JobPosition_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: left;
    max-width: 120px;
    min-width: 120px;
    margin-right: 24px;
  }

  .ReferSelectList_Body_AcqDate_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: left;
    max-width: 84px;
    min-width: 84px;
  }

  .ReferSelectList_EmployeeList_Button_BG:hover {
    background-color: rgb(55, 105, 252);
    transition: 0.7s;
  }

  .ReferSelectList_EmployeeList_Button_BG:hover .ReferSelectList_Body_DocumentStatus_Copy {
    color: white;
  }
  .ReferSelectList_EmployeeList_Button_BG:hover .ReferSelectList_Body_DocumentName_Copy {
    color: white;
  }
  .ReferSelectList_EmployeeList_Button_BG:hover .ReferSelectList_Body_Name_Copy {
    color: white;
  }
  .ReferSelectList_EmployeeList_Button_BG:hover .ReferSelectList_Body_YYYYMMDDDate_Copy {
    color: white;
  }
  .ReferSelectList_EmployeeList_Button_BG:hover .ReferSelectList_Body_TelNumber_Copy {
    color: white;
  }
  .ReferSelectList_EmployeeList_Button_BG:hover .ReferSelectList_Body_Department_Copy {
    color: white;
  }
  .ReferSelectList_EmployeeList_Button_BG:hover .ReferSelectList_Body_WorkPeriod_Copy {
    color: white;
  }
  .ReferSelectList_EmployeeList_Button_BG:hover .ReferSelectList_Body_JobPosition_Copy {
    color: white;
  }
  .ReferSelectList_EmployeeList_Button_BG:hover .ReferSelectList_Body_AcqDate_Copy {
    color: white;
  }
  .ReferSelectList_EmployeeList_Button_BG:hover .ReferSelectList_Body_InvestorCompanyShareholderReport_Copy {
    color: white;
  }
  .ReferSelectList_EmployeeList_Button_BG:hover .List_Body_CompanyName {
    color: white;
  }
  .ReferSelectList_EmployeeList_Button_BG:hover .List_Body_Date {
    color: white;
  }
  .ReferSelectList_EmployeeList_Button_BG:hover .List_Body_UserName {
    color: white;
  }
  .ReferSelectList_EmployeeList_Button_BG:hover .List_Body_TelNumber {
    color: white;
  }
  .ReferSelectList_EmployeeList_Button_BG:hover .List_Body_Status {
    color: white;
  }

  .PDFDetail_BG {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    background-color: rgb(255, 255, 255);
    /* margin-top: 48px; */
    margin-bottom: 64px;
  }

  .Contract_Label_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    width: 60px;
  }

  .Contract_Body_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .Contract_UserData_CT {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 24px;
  }

  .Contract_Divider {
    display: flex;
    flex-direction: column;
    height: 1px;
    width: 100%;
    background-color: rgb(227, 227, 227);
    margin-top: 24px;
  }
  .NewEmployee_Divider {
    display: flex;
    flex-direction: column;
    height: 1px;
    width: 574px;
    background-color: rgb(170, 170, 170);
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .TabBar_Short_CT {
    display: flex;
    flex-direction: column;
    /* background-color: brown; */
    width: 100%;
    margin-top: 48px;
  }

  .Detail_Modify_Title_BG {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 75vw;
    /* max-width: 1024px; */
    background-color: rgb(246, 246, 246);
    height: 64px;
  }

  .TitleBG_Button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 84px;
    height: 40px;
    border-radius: 8px;
    border-width: 0px;
    background-color: rgb(231, 239, 255);

    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    margin-left: 12px;
    cursor: pointer;
    padding: 0px;
  }

  .TitleBG_Delete_Button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 84px;
    height: 40px;
    border-radius: 8px;
    border-width: 0px;
    background-color: rgb(255, 233, 233);

    color: rgb(255, 115, 115);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    margin-left: 12px;
    cursor: pointer;
    padding: 0px;
  }

  .Resigner_BG_ResignerDetail {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    width: 640px;
    height: auto;
    background-color: rgb(242, 244, 246);
    margin-top: 48px;
    margin-bottom: 64px;
    padding-top: 12px;
    padding-bottom: 24px;
  }

  .EmployeeDetail_Delete_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: -3%;
    line-height: 130%;
    margin-bottom: 4px;
    text-align: center;
  }

  .Home_Head_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    text-align: left;
    max-width: 1024px;
  }

  .Widget_Two_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 32px;
  }

  .Widget_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
    border-radius: 12px;
    border-color: rgb(227, 227, 227);
    border-width: 1px;
    border-style: solid;
  }

  .WidgetList_Title_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 24px;
  }

  .WidgetList_Title_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    text-align: left;
  }

  .WidgetList_Button_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  .WidgetList_Button_Copy {
    background-color: transparent;
    border-style: none;
    padding: 0px;
    margin-left: 4px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    color: #aaaaaa;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -3%;
    text-align: left;
    cursor: pointer;
  }

  .WidgetList_Title_Divider {
    display: flex;
    flex-direction: column;
    height: 1px;
    width: 100%;
    background-color: rgb(170, 170, 170);
    margin-top: 12px;
  }

  .WidgetList_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;
    background-color: transparent;
    border-style: none;
    padding: 0px;
  }

  .WidgetList_EmptyList_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: transparent;
    border-style: none;
    padding: 0px;
  }

  .WidgetList_Body_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 12px;
  }

  .WidgetList_Body_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: left;
    width: 418px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 8px;
  }

  .WidgetList_Body_Divider {
    display: flex;
    flex-direction: column;
    height: 1px;
    width: 100%;
    background-color: rgb(227, 227, 227);
    margin-top: 11px;
  }

  .Home_LargeBar_Divider {
    display: flex;
    flex-direction: column;
    height: 1px;
    width: 1024px;
    background-color: rgb(227, 227, 227);
  }

  .Home_LargeBar_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 96px;
    width: 1024px;
    background-color: transparent;
    padding: 0px;
    border-style: none;
    cursor: pointer;
  }

  .Home_LargeBar_Text_CT {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .Home_LargeBar_Category_Copy {
    color: #94bafd;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    margin-bottom: 8px;
  }

  .Home_LargeBar_Body_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    text-align: left;
  }

  .Notification_CT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-style: none;
    background-color: transparent;
    padding: 0px;
    cursor: pointer;
  }

  .Notification_Contents_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .Notification_Body_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
  }

  .Notification_Date_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: right;

    margin-right: 8px;
  }

  .Notification_DeleteList_CT {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 32px;
    margin-bottom: 20px;
  }

  .Notification_Delete_Button {
    background-color: transparent;
    border-style: none;
    padding: 0px;
    margin-left: 32px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -3%;
    text-align: left;
    text-decoration: underline;
    cursor: pointer;
  }

  .Payment_Button_BG {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 320px;
    height: 64px;
    border-style: solid;
    border-width: 2px;
    border-color: rgb(55, 105, 252);
    border-radius: 8px;
    background-color: rgb(55, 105, 252);
    cursor: pointer;

    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    text-align: center;
  }

  .Document_PDF_Contents_CT {
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: flex-start;
    margin-top: 48px;
    margin-bottom: 192px;
  }

  .Payment_Introduce_BG {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: rgb(255, 255, 255);
    padding: 32px;
    border-style: none;
    border-radius: 24px;
  }

  .Pagination_CT {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
  }

  .Pagination_ActivePage_Copy {
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    margin-left: 4px;
    margin-right: 4px;
    background-color: transparent;
    border-style: none;
    width: 32px;
    cursor: pointer;
  }

  .Pagination_InactivePage_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    margin-left: 4px;
    margin-right: 4px;
    background-color: transparent;
    border-style: none;
    width: 32px;
    cursor: pointer;
  }

  .Pagination_ArrowButton_CT {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-style: none;
    cursor: pointer;
  }

  .ContractInput_WidthBody_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .Report_TextArea_BG {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 402px;
    height: 56px;

    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
    line-height: 150%;
    overflow-y: scroll;
    resize: none;
    outline: none;
  }

  .Report_Investment_BG {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 394px;
    background-color: #f2f4f6;
    padding: 12px;
    border-width: 1px;
    border-color: rgb(227, 227, 227);
    border-style: solid;
    margin-bottom: 12px;
  }

  .Profile_InvestorData_CT {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .Profile_InvestorData_Line {
    display: flex;
    flex-direction: row;
    width: 640px;
    height: 1px;
    background-color: #94bafd;
    margin-top: 12px;
  }

  .PageTitle_CT {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 32px;
    margin-bottom: 32px;
  }

  .PageTitle_Icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }

  .PageTitle_Icon_Button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin-right: 8px;
    background-color: transparent;
    border-style: none;
    cursor: pointer;
  }

  .PageTitle_Title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #464646;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
  }

  .Contents_Area_CT {
    display: flex;
    flex-direction: column;
    margin-top: 36px;
    padding-bottom: 72px;
    width: 100%;
    width: 640px;
    /* min-width: 640px;
    max-width: 1238px; */
    height: auto;
  }

  .Contents_Area_Left_Margin {
    width: 40%;
    /* max-width: 460px; */
    max-width: 760px;
    min-width: 264px;
  }

  .Contents_Area_Right_Margin {
    width: 30%;
    max-width: 520px;
    min-width: 24px;
  }

  .Widget_CT_TBD {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 640px;
    /* max-width: 1238px; */
    max-width: 640px;
    border-style: solid;
    border-color: #e3e3e3;
    border-width: 1px;
    border-radius: 12px;
    background-color: white;
    /* height: 908px; */
    height: 821px;
    box-sizing: border-box;
  }

  .Widget_Title_CT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .Widget_Title_Left_Area {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-left: 24px;
  }

  .Widget_Title_Right_Area {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-right: 24px;
  }

  .TabMenuListWidget_Title_Tab {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    border-style: none;
    background-color: transparent;
    margin-right: 24px;
    cursor: pointer;
  }

  .Widget_Title_Copy {
    display: flex;
    color: #464646;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  }

  .TabMenuList_Label_DocumentName {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    width: 100%;
    max-width: 1048px;
    min-width: 54px;
    margin-left: 12px;
    margin-right: 24px;
  }

  .Widget_Divider {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 1px;
    background-color: #e3e3e3;
  }

  .ListWidget_List_CT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    max-width: 1238px;
    width: 100%;
    box-sizing: border-box;
  }

  .ListWidget_Label_CT {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 8px;
  }

  .CreateSeal_Info_Copy {
    color: rgb(170, 170, 170);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -3%;
    line-height: 150%;
    margin-top: 12px;
    margin-bottom: 24px;
  }

  .ListWidget_Body_Scroll {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background-color: white;
    margin-bottom: 24px;
    margin-top: 12px;
  }

  .Widget_Contents_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
    padding-bottom: 32px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    /* background-color: rgb(255, 212, 212); */
  }

  .Widget_Contents_Upper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }

  .Widget_Contents_Lower {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .Widget_Head_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    margin-bottom: 12px;
  }

  .Widget_Sub_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
  }

  .Widget_Number_Copy {
    color: #aaaaaa;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
    text-align: right;
  }

  .Widget_Header_CT {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 48px;
  }

  .Widget_Header_Text_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }

  .Widget_Header_Number_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    min-width: 80px;
    max-width: 80px;
    margin-left: 24px;
  }

  .Widget_Checkbox_Card_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 48px;
    background-color: white;
    max-width: 712px;
    border-style: solid;
    border-radius: 8px;
    border-width: 1px;
    border-color: #e3e3e3;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    box-sizing: border-box;
    /* margin-bottom: 12px; */
  }

  .Widget_Checkbox_Card_Left {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }

  .Widget_Checkbox_Card_Right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    min-width: 24px;
    max-width: 128px;
    margin-left: 24px;
  }

  .Widget_Checkbox_Card_Icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px;
    padding: 0px;
    background-color: transparent;
    border-style: none;
    cursor: pointer;
  }

  .Widget_Checkbox_Card_Title_Copy {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }

  .Widget_Checkbox_Card_Sub_Copy {
    color: #aaaaaa;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }

  .Widget_Solid_Button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px;
    padding: 0px;
    background-color: #e7efff;
    border-style: none;
    border-radius: 8px;
    cursor: pointer;
    width: 590px;
    height: 48px;
  }

  .Widget_Solid_Button_Label {
    color: #3769fc;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  }

  .Widget_Solid_Button_Small {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px;
    padding: 0px;
    background-color: #e7efff;
    border-style: none;
    border-radius: 8px;
    cursor: pointer;
    width: 590px;
    height: 36px;
  }

  .Widget_Solid_Button_Label_Small {
    color: #3769fc;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    width: 590px;
    text-align: center;
  }

  .Widget_Scroll_Contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    /* height: 100%; */
    overflow-y: scroll;
    box-sizing: border-box;
  }

  .Widget_UnScroll_Contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
  }

  .Widget_Scroll_Contents::-webkit-scrollbar {
    width: 6px;
    background-color: #ffffff;
  }

  .Widget_Scroll_Contents::-webkit-scrollbar-thumb {
    width: 6px;
    background-color: #e3e3e3;
    border-radius: 3px;
  }

  .Widget_Messege_BG {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    border-color: #e3e3e3;
    background-color: white;
    padding: 16px;
    box-sizing: border-box;
  }

  .Widget_Messege_Copy {
    color: #464646;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
  }

  .Widget_Messege_Label {
    color: #464646;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 8px;
  }

  .ListWidget_Body_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    min-height: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .ListWidget_Body_Left_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .ListWidget_Body_Right_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  .List_Label_CompanyName {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    width: 100%;
    max-width: 100%;
    margin-left: 12px;
    margin-right: 24px;
    text-align: left;
  }

  .List_Label_UserName {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    width: 74px;
    max-width: 74px;
    min-width: 74px;
    margin-left: 12px;
    margin-right: 24px;
    text-align: left;
  }

  .List_Label_TelNumber {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    width: 106px;
    max-width: 106px;
    min-width: 106px;
    margin-right: 24px;
    text-align: left;
  }

  .List_Label_Status {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    width: 38px;
    max-width: 38px;
    min-width: 38px;
    margin-right: 24px;
    text-align: left;
  }

  .List_Label_Date {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    min-width: 74px;
    max-width: 74px;
    margin-right: 12px;
    text-align: left;
  }

  .List_Body_CompanyName {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    width: 100%;
    max-width: 100%;
    margin-left: 12px;
    margin-right: 24px;
    text-align: left;
  }

  .List_Body_UserName {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    width: 74px;
    max-width: 74px;
    min-width: 74px;
    margin-left: 12px;
    margin-right: 24px;
    text-align: left;
  }

  .List_Body_TelNumber {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    width: 106px;
    max-width: 106px;
    min-width: 106px;
    margin-right: 24px;
    text-align: left;
  }

  .List_Body_Status {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    width: 38px;
    max-width: 38px;
    min-width: 38px;
    margin-right: 24px;
    text-align: left;
  }

  .List_Body_Date {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    min-width: 74px;
    max-width: 74px;
    margin-right: 12px;
    text-align: left;
  }

  .List_Label_Divider {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 1px;
    background-color: #aaaaaa;
  }

  .List_Body_Divider {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 1px;
    background-color: #e3e3e3;
  }

  .Widget_Scroll_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    /* background-color: rgb(191, 73, 73); */
    overflow-y: scroll;
  }

  .Widget_Scroll_CT::-webkit-scrollbar {
    display: none; /* Chrome , Safari , Opera */
  }

  .Widget_Infomation_CT {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .Widget_Infomation_Line_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .Widget_Infomation_Content_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 272px;
  }

  .Widget_Infomation_Label {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    min-width: 49px;
    max-width: 49px;
    text-align: left;
    width: 49px;
  }

  .Widget_Infomation_Body {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
  }

  .Widget_Infomation_Vertical_Divider {
    height: 12px;
    width: 1px;
    background-color: #aaaaaa;
    margin-left: 16px;
    margin-right: 16px;
  }

  .Widget_Documents_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 590px;
    height: 454px;
    overflow-y: scroll;
  }

  .Widget_Documents_CT::-webkit-scrollbar {
    width: 6px;
    background-color: #ffffff;
  }

  .Widget_Documents_CT::-webkit-scrollbar-thumb {
    width: 6px;
    background-color: #e3e3e3;
    border-radius: 3px;
  }

  .Widget_NoDocuments_CT {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 590px;
    height: 454px;
  }

  .Widget_Documents_Label {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    margin-bottom: 12px;
  }

  .Widget_Documents_Body {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .Widget_Documents_Content_CT {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 12px;
  }

  .Widget_Documents_Content_BG {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 488px;
    height: 34px;
    box-sizing: border-box;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 8px;
    border-color: #e3e3e3;
    border-style: solid;
    border-width: 1px;
    margin-right: 12px;
  }

  .Widget_Documents_Content_Button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 72px;

    height: 34px;
    border-radius: 8px;
    border-style: none;
    padding: 0px;
    margin: 0px;
    background-color: #e7efff;

    color: #3769fc;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: center;

    cursor: pointer;
  }

  .Widget_None_Contents_Copy {
    color: #aaaaaa;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }

  .Modal_CTA_Button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px;
    padding: 0px;
    background-color: #3769fc;
    border-style: none;
    border-radius: 8px;
    cursor: pointer;
    width: 432px;
    height: 48px;
    margin-top: 48px;
    margin-bottom: 32px;
  }

  .Modal_CTA_Button_Label {
    color: #ffffff;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  }

  .None_List_Copy {
    color: #aaaaaa;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
  }

  .InputField_Container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .InputField_Input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    height: 36px;
    box-sizing: border-box;
    background-color: #ffffff;

    border-style: solid;
    border-width: 1px;
    border-color: #e3e3e3;
    border-radius: 8px;

    padding-left: 12px;
    padding-right: 12px;

    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #464646;
    text-align: left;
    outline: none;
  }

  .InputField_Button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    height: 36px;
    box-sizing: border-box;
    background-color: #e7efff;

    border-style: none;
    border-radius: 8px;

    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 12px;
    margin-right: 0px;

    cursor: pointer;
  }

  .ModalTitle_Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 592px;
  }

  .ModalTitle_TitleAndIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 100%;
  }

  .ModalTitle_IconButton {
    margin: 0px;
    padding: 0px;
    border-style: none;
    background-color: transparent;
    cursor: pointer;
  }

  .ModalTitle_Divider {
    width: 100%;
    height: 1px;
    background-color: #aaaaaa;
    margin-top: 12px;
  }

  .PageTitle_Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 592px;
  }

  .PageTitle_TitleAndIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    height: 32px;
  }

  .PageTitle_TitleButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 0px;
    border-style: none;
    background-color: transparent;
    cursor: pointer;
  }

  .PageTitle_ButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 32px;
  }

  .WidgetTitle_Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 640px;
    height: 68px;

    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
  }

  .WidgetTitle_TitleAndIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .WidgetTitle_ButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .WidgetTitle_VerticalDivider {
    height: 18px;
    width: 1px;
    background-color: #e3e3e3;
    margin-left: 12px;
    margin-right: 12px;
  }

  .WidgetTab_Container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 592px;
    width: 100%;
    height: 44px;
  }

  .WidgetTab_Button {
    display: flex;
    justify-content: center;
    align-items: center;

    border-style: none;
    background-color: transparent;
    margin-right: 24px;
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px;
    cursor: pointer;
  }

  .WidgetNavigation_Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 592px;
  }

  .WidgetNavigation_ButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .WidgetNavigation_Button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px;
    padding: 0px;
    background-color: transparent;
    border-style: none;
    cursor: pointer;
    height: 19px;
  }

  .WidgetNavigation_Divider {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 1px;
    background-color: #464646;
    margin-top: 12px;
  }

  .WidgetDescription_Container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 48px;
  }

  .WidgetDescription_TitleTextContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }

  .WidgetDescription_StepNumberContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    min-width: 80px;
    max-width: 80px;
    margin-left: 24px;
  }

  .WidgetList_Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .WidgetList_BodyText {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
  }

  .WidgetList_LabelText {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
  }

  .WidgetList_BodyText {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
  }

  .WidgetList_LabelContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin-bottom: 8px;
  }

  .WidgetList_Divider {
    width: 100%;
    height: 1px;
    background-color: rgb(170, 170, 170);
  }

  .WidgetList_ListContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .WidgetList_Button {
    display: flex;
    flex-direction: column;
    border-style: none;
    background-color: rgb(255, 255, 255);
    cursor: pointer;
    width: 100%;
    margin: 0px;
    padding: 0px;
  }

  .WidgetList_Button:hover {
    background-color: rgb(55, 105, 252);
    transition: 0.7s;
  }

  .WidgetList_Button:hover .WidgetList_BodyText {
    color: white;
  }

  .WidgetList_ContentsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    min-height: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .WidgetList_ContentsDivider {
    width: 100%;
    height: 1px;
    background-color: rgb(227, 227, 227);
  }

  .WidgetList_Pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .WidgetList_CheckListContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    min-height: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .WidgetList_DetailDataButton {
    min-width: 54px;
    max-width: 54px;
    background-color: transparent;
    border-style: none;
    padding: 0px;
    margin-left: 32px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    color: rgb(55, 105, 252);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -3%;
    text-align: left;
    text-decoration: underline;
    cursor: pointer;
  }

  .CheckList_Container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 592px;
  }

  .WidgetList_ScrollContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    /* padding-right: 12px; */
    overflow-y: auto;
    box-sizing: border-box;
  }

  .WidgetList_SubscriptionTextButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    width: 100%;
    height: 74px;
    border-radius: 8px;
    border-width: 1px;
    border-color: #e3e3e3;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 16px;
    padding-bottom: 16px;
    box-sizing: border-box;
    outline: none;
    cursor: pointer;
    border-style: solid;
    border-width: 1px;
    border-color: #e3e3e3;
  }

  .WidgetList_SubscriptionTextButton_TextContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .WidgetList_SubscriptionTextButton_Label {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    margin-bottom: 8px;
  }

  .WidgetList_SubscriptionTextButton_Body {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
  }

  .Widget_CTA_Button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px;
    padding: 0px;
    background-color: #3769fc;
    border-style: none;
    border-radius: 8px;
    cursor: pointer;
    width: 590px;
    height: 48px;
  }

  .Widget_CTA_Button_Label {
    color: #ffffff;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  }

  .WidgetList_Link_Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .WidgetList_Link_BG {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-color: #ffffff;
    border-style: solid;
    border-radius: 8px;
    border-width: 1px;
    border-color: #e3e3e3;
    padding-left: 12px;
    padding-right: 12px;
    width: 544px;
    height: 36px;
    box-sizing: border-box;
  }

  .WidgetList_Link_Body {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .WidgetList_Link_Button_Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background-color: #e7efff;
    border-radius: 8px;
    border-style: none;
    cursor: pointer;
  }
}
